// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import axiosService from "./services/axios"
import countdown from "countdown";
import moment from "moment";
import AlpineEditor from 'alpine-editor';

// Helpers
import helpers from "./helpers"

// Styles
import  "../stylesheet/application.scss";

// Components
import "./components/dialog"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Definitions
window.helpers = helpers;
window.axios = axiosService.axios;
window.moment = moment;
window.countdownUnits = countdown.DAYS | countdown.HOURS | countdown.MINUTES | countdown.SECONDS
window.countdown = countdown;

// Dom loaded
window.addEventListener('DOMContentLoaded', axiosService.setupCSRFToken)

document.addEventListener('alpine:init', () => {
  Alpine.store('reservations', {
    occupied: [],
    arrive: [],
  })
})