import consumer from "./consumer"

consumer.subscriptions.create("AlertsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const palmettoAlert = new CustomEvent('palmetto-alert', {
      detail: {
        check_in_time: data.check_in_time
      }
    });
    document.dispatchEvent(palmettoAlert)
  }
});
