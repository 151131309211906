window.dialog = function(){
  return {
    open: false,
    options: {
      title: '',
      content: '',
      confirm: false,
      delete_confirm: false,
      accept: false,
      handle: () => { this.open = false },
      handleCancel: () => {}
    },
    call(options){
      this.open = true;
      this.options = {
        ...this.options,
        ...options
      }
    },
    cancelDialog(v){
      this.open = v;
      this.options = {
        title: '',
        content: '',
        confirm: false,
        delete_confirm: false,
        accept: false,
        handle: () => { this.open = false },
        handleCancel: () => {}
      };
    }
  }
}

window.dialogReservation = function(){
  return {
    open: false,
    companyName: '',
    ticketNumber: '',
    options: {},
    call(options){
      this.options = {
        ...this.options,
        ...options
      };
      this.companyName = '';
      this.ticketNumber = '';
      this.setOpen(true)
      
      if(options && options.handleSubmit){
        this.handleSubmit = options.handleSubmit
      }
      if(options && options.handleCancel){
        this.handleCancel = options.handleCancel
      }
    },
    handleSubmit(){},
    handleCancel(){},
    submit(){
      this.handleSubmit({
        company: this.companyName,
        ticket: this.ticketNumber
      })
    },
    cancel(){
      this.handleCancel();
      this.open = false
    },
    setOpen(open){
      this.open = open
    }
  }
}

window.dialogInfo = function(){
  return {
    open: false,
    options: {
      id: '',
      user_first_name: '',
      user_last_name: '',
      user_phone_number: '',
      user_alternative_phone_number: '',
      user_email: '',
      user_date_of_birth: '',
      user_image: '',
      license_dot: '',
      license_identification: '',
      license_date_of_expiration: '',
      license_address_line_1: '',
      license_address_line_2: '',
      license_city: '',
      license_state: '',
      license_zip_code: '',
      license_image: '',
      check_in_time: '',
      handle: () => { this.open = false }
    },
    call(options){
      this.open = true;
      this.options = options
    }
  }
}


window.showerDialog = function(){
  return {
    open: false,
    options: {
      license_image: false,
      display_name: false,
      phone_number: false,
      license_identification: false,
      license_state: false,
      truck: false,
      truck_patent: false,
      truck_state: false,
      sale_condition: false,
      handle: () => { this.open = false }
    },
    call(options){
      this.open = true;
      this.options = options
    },
    setOpen(open){
      this.open = open
    }
  }
}