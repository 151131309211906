import axios from 'axios';

function setupCSRFToken() {
  const csrfToken = document.querySelector("meta[name=csrf-token]").content
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
}

export default {
  axios,
  setupCSRFToken
}