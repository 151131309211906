import consumer from "./consumer"

consumer.subscriptions.create("PendingReservationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const pending_reservation_event = new CustomEvent('palmetto:pending-reservation-created', {
      bubbles: true,
      detail: {
        pending_reservations_total: data.pending_reservations_total
      }
    });

    document.dispatchEvent(pending_reservation_event)
  }
});
