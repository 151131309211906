import consumer from "./consumer"

consumer.subscriptions.create("ShowerCreatedOrUpdatedChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const event = new CustomEvent('palmetto:shower_created_or_updated', {
      bubbles: true,
      detail: {
        showers: data.showers
      }
    });

    document.dispatchEvent(event)
  }
});
