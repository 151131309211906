import consumer from "./consumer"

consumer.subscriptions.create("ReservationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if( typeof data.spots_available != 'undefined' && document.getElementById('home_spots_available') ){
      document.getElementById('home_spots_available').innerText = data.spots_available
    }
  }
});
