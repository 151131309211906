import consumer from "./consumer"

consumer.subscriptions.create("ModerationsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if( data.pushToArray ){
      if( !!data.reservation.reservation_check_in ){
        Alpine.store('reservations').occupied.push( data.reservation )
      } else {
        Alpine.store('reservations').arrive.push( data.reservation )
      }
    }

    const reservationCreatedEvent = new CustomEvent('palmetto:reservation-created', {
      bubbles: true,
      detail: {
        reservation: data.reservation,
        spot_index: data.reservation.spot_index,
        occupied: data.reservation.spot_occupied,
        for_company_use: data.reservation.spot_for_company_use,
        arrived: data.reservation.reservation_check_in
      }
    });

    document.dispatchEvent(reservationCreatedEvent)

  }
});
